<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      statData: [
        { type: "line", title: "Total de clientes", value: "-", icon: "bx-purchase-tag-alt", },
      ],

      total: 0,

      fields: [
        { key: "name", label: "Nome" },
        { key: "email", label: "E-mail" },
        { key: "cellphone", label: "Celular" },
        { key: "total_orders", label: "Total de Pedidos" },
        { key: "last_order", label: "Último Pedido" },
      ],
      items: null,

      status: "all",
      sortBy: "name",
      sortDesc: false,
      currentPage: "1",
      perPage: "25",
    };
  },
  methods: {
    getClients() {
      this.items = null;

      api
        .get("sales/clients")
        .then((response) => {
          if (response.data.status == "success") {
            this.total = response.data.total;
            this.items = response.data.list;

            this.statData = [
              { type: "line", title: "Total de clientes", value: this.total.toString(), icon: "bx-purchase-tag-alt", },
            ];
          } else {
            this.total = 0;
            this.items = [];
          }
        })
        .catch((error) => {
          if (error) {
            this.total = 0;
            this.items = [];
          }
        })
    },
  },
  mounted() {
    this.getClients();
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Pedidos</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Clientes</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-3 col-xl-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div v-if="!items" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <b-spinner variant="default" role="status"></b-spinner>
      </div>
    </div>

    <div class="card" v-else-if="items && items.length == 0">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum cliente encontrado
        </div>
      </div>
    </div>

    <div v-else class="card">
      <div class="card-body">
        <div class="table-responsive">
          <b-table :items="items" :fields="fields" class="table-nowrap table-style table-hover min-vh-25" head-variant="light" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :current-page="currentPage" :per-page="perPage" responsive>
            <template #cell(total)="row">
              {{ row.item.total | currency }}
            </template>
          </b-table>
        </div>

        <b-pagination class="mt-3" v-model="currentPage" :total-rows="total" :per-page="perPage" pills align="center"></b-pagination>
      </div>
    </div>
  </Layout>
</template>